module.exports = {
	uiState: {mode: "loading"},
	spreadsheetTemplates: {},
	isDirty: false,
	nonModalUi: {},
	queryProfile: {},
	mimeTypeMappings: {},
	githubConfig: {},
	upload: {},
	providers: []
}